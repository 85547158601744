// uC12
import dayjs from 'dayjs'
import { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import useFetchDetail from 'src/hooks/useFetchDetail'
import textJP from 'src/langs/ja'
import LayoutMain from 'src/layouts/LayoutMain'
import { apiGetInvoiceDetail } from 'src/services/ProfileService'
import { formatCurrency } from 'src/utils'
import NotFound from 'src/pages/NotFound'
import RenderIf from 'src/components/RenderIf'
import { YYYY_MM_DD_unit } from 'src/constants'

const InvoiceDetail = () => {
  const { id } = useParams()
  const endpoint = useCallback(() => apiGetInvoiceDetail(id as string), [id])

  const { detail, errorType } = useFetchDetail({ endpoint })

  return (
    <>
      {errorType ? (
        <NotFound type={errorType} />
      ) : (
        <LayoutMain
          showHeader={false}
          showHeaderWeb
          title={textJP.invoice_detail.title}
        >
          <RenderIf isTrue={!!detail?.id}>
            <div className='ut_bg_lightBlue'>
              <div className='ly_container'>
                <div className='ly_receipt'>
                  <div className='ly_receipt_inner'>
                    <div className='bl_grid'>
                      <div className='el_txt_bold700 el_txt_lg'>
                        {textJP.invoice_detail.title}
                      </div>
                      <div className='el_txt_right'>
                        <p>{dayjs(detail?.date_use).format(YYYY_MM_DD_unit)}</p>
                        <p>{`No.${detail?.id}`}</p>
                      </div>
                    </div>
                    <RenderIf isTrue={!!detail?.full_name}>
                      <p className='el_txt_md'>
                        <span>{detail?.full_name}</span>
                      </p>
                    </RenderIf>
                    <div className='bl_receiptPrice ut_mt20'>
                      <span className='bl_receiptPrice_before'>￥</span>
                      <span>{formatCurrency(detail?.purchase_amount)}</span>
                      <span className='bl_receiptPrice_after'>円</span>
                    </div>
                    <p className='ut_mt10'>
                      <span> {textJP.invoice_detail.notice}</span>
                    </p>
                    <p className='el_txt_center el_txt_lg el_txt_bold500 ut_mt20 ut_mb10'>
                      {textJP.invoice_detail.message_1}
                    </p>
                  </div>
                  <div className='bl_receiptFooter'>
                    <RenderIf isTrue={!!detail?.organization_name}>
                      <p>{detail?.organization_name}</p>
                    </RenderIf>
                    <RenderIf isTrue={!!detail?.postal_code}>
                      <span className='mr-2'>{`〒${detail?.postal_code}`}</span>
                    </RenderIf>
                    <RenderIf isTrue={!!detail?.address}>
                      <span>{detail?.address}</span>
                    </RenderIf>
                  </div>
                </div>
              </div>
            </div>
          </RenderIf>
        </LayoutMain>
      )}
    </>
  )
}

export default InvoiceDetail
