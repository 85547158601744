// uP30
import LayoutMain from 'src/layouts/LayoutMain'
import textJP from 'src/langs/ja'
import { useEffect, useState } from 'react'
import ErrorResponse from 'src/components/ErrorResponse'
import { Button } from '@mui/material'
import { RESPONSE_STATUS } from 'src/constants/enum'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { clearBlock, setBlock } from 'src/stores/blockUI'
import {
  apiGetAvailablePurchase,
  apiDeletePaymentMethod
} from 'src/services/UserService'
import path from 'src/routers/Path'
import ConfirmDialog from 'src/components/dialog/ConfirmDialog'
import listCredit from 'src/assets/images/list-credit.png'
import InfoDialog from 'src/components/dialog/InfoDialog'
import { RootState } from 'src/stores'
import { clearPaymentInfo } from 'src/stores/PaymentInfo'
import {
  getInitCardNumberDetail,
  handleNavigateApp,
  hasAnyValueObject
} from 'src/utils'
import RenderIf from 'src/components/RenderIf'

const PaymentInformationDetail = () => {
  const [openDelete, setOpenDelete] = useState<boolean>(false)
  const [cannotDelete, setCannotDelete] = useState<boolean>(false)
  const [errorMess, setErrorMess] = useState<string>('')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [lstPurchase, setLstPurchase] = useState([])
  const paymentInfo = useSelector((state: RootState) => state.paymentInfo)
  const { exp_month, exp_year, name, last4, brand } = paymentInfo || {}
  const expiryDate = exp_month ? `${exp_month}/${exp_year}` : ''
  const cardNumber = getInitCardNumberDetail(last4, brand)
  const cvc = hasAnyValueObject(paymentInfo, ['loading']) ? '****' : ''

  const getListAvailablePurchase = async () => {
    const response = await apiGetAvailablePurchase()
    if (response?.status === RESPONSE_STATUS.SUCCESS) {
      setLstPurchase(response.data.data)
    }
  }

  useEffect(() => {
    getListAvailablePurchase()
  }, [])

  const handleDelete = () => {
    if (lstPurchase.length > 0) {
      setCannotDelete(true)
    } else {
      setOpenDelete(true)
    }
  }

  const confirmDelete = async () => {
    try {
      setErrorMess('')
      dispatch(setBlock())
      const res = await apiDeletePaymentMethod()
      if (res?.status === RESPONSE_STATUS.SUCCESS) {
        dispatch(clearPaymentInfo())
        handleNavigateApp({
          pathMobile: path.close_web,
          pathBrowser: path.home,
          navigate
        })
      }
    } finally {
      dispatch(clearBlock())
    }
  }

  return (
    <LayoutMain
      title={textJP.payment_info.title}
      wrapperClassName='ly_container'
    >
      <>
        <div className='bl_flex justify_center mb-5'>
          <img src={listCredit} alt='' className='h-auto' />
        </div>

        <div className='bl_form ut_mt14'>
          <div className='bl_form_body'>
            <div className='bl_form'>
              <label>{textJP.payment_info.card_number}</label>
              <input value={cardNumber} readOnly className='el_input' />
            </div>
          </div>

          <div className='bl_form_body'>
            <div className='bl_form'>
              <label>{textJP.payment_info.cvc}</label>
              <input value={cvc} readOnly className='el_input' />
            </div>
          </div>

          <div className='bl_form_body'>
            <div className='bl_form'>
              <label>{textJP.payment_info.expiry_date}</label>
              <input value={expiryDate || ''} readOnly className='el_input' />
            </div>
          </div>

          <div className='bl_form_body'>
            <div className='bl_form'>
              <label className='text-sm'>{textJP.payment_info.card_name}</label>
              <input value={name || ''} readOnly className='el_input' />
            </div>
          </div>
        </div>

        <div className='el_txt_center ut_mt20'>
          <ErrorResponse errorMess={errorMess} />
          <Button
            type='button'
            onClick={() =>
              handleNavigateApp({
                pathMobile: path.payment_infor_update,
                pathBrowser: path.payment_infor_update,
                navigate
              })
            }
            variant='contained'
            className='el_btn_common'
          >
            {textJP.btn.update}
          </Button>
        </div>

        <RenderIf isTrue={hasAnyValueObject(paymentInfo, ['loading'])}>
          <div className='el_txt_center ut_mt20'>
            <button
              className='el_txt_link'
              type='button'
              onClick={handleDelete}
            >
              {textJP.payment_info.delete_message}
            </button>
          </div>
        </RenderIf>

        <ConfirmDialog
          title={textJP.payment_info.confirm_title}
          open={openDelete}
          setOpen={setOpenDelete}
          cancelClassName='bl_dialog_btn__black'
          confirmClassName='bl_dialog_btn__red'
          cancelLabel={textJP.btn.back}
          confirmLabel={textJP.btn.delete}
          onConfirm={confirmDelete}
        />
        <InfoDialog
          open={cannotDelete}
          setOpen={setCannotDelete}
          title={textJP.payment_info.cannot_delete_title}
          description={textJP.payment_info.cannot_delete_description}
          closeLabel={textJP.btn.ok}
        />
      </>
    </LayoutMain>
  )
}

export default PaymentInformationDetail
