import LayoutMain from 'src/layouts/LayoutMain'
import textJP from 'src/langs/ja'
import path from 'src/routers/Path'
import listCredit from 'src/assets/images/list-credit.png'
import { handleNavigateApp } from 'src/utils'
import { useSearchParams } from 'react-router-dom'
import { Button } from '@mui/material'
import { useSelector } from 'react-redux'
import { RootState } from 'src/stores'
import { useEffect } from 'react'

const PaymentConfirm = () => {
  const [searchParams] = useSearchParams()
  const chargeId = searchParams.get('charge_id') as string
  const status = searchParams.get('status') as string
  const initPayJP = useSelector((state: RootState) => state.initPayJP)

  useEffect(() => {
    if (status && status === 'done') {
      handleNavigateApp({
        pathMobile: path.payment_done
      })
    }
  }, [status])

  const requestPayment = () => {
    window.Payjp.openThreeDSecureDialog(chargeId)
      .then((result: any) => {
        console.log('result', result)
        handleNavigateApp({
          pathMobile: path.payment_done
        })
      })
      .catch((error: any) => {
        console.log('error', error)
      })
  }

  return (
    <LayoutMain
      title={textJP.payment_info.title}
      wrapperClassName='ly_container'
    >
      <>
        <div className='bl_flex justify_center mb-5'>
          <img src={listCredit} alt='' className='h-auto' />
        </div>

        <div style={{ fontSize: 16 }} className='bl_form ut_mt20'>
          <div>{textJP.common.payment_confirm}</div>
        </div>

        <div style={{ marginTop: 40 }} className='el_txt_center'>
          <Button
            disabled={!initPayJP}
            type='button'
            onClick={requestPayment}
            variant='contained'
            className='el_btn_common'
          >
            {textJP.btn.update}
          </Button>
        </div>
      </>
    </LayoutMain>
  )
}

export default PaymentConfirm
