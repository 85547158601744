import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { PaymentInfoValues } from 'src/models'

const detail: PaymentInfoValues = {
  brand: '',
  exp_month: '',
  exp_year: '',
  last4: '',
  name: '',
  loading: true
}

export const paymentInfo = createSlice({
  name: 'paymentInfo',
  initialState: detail,
  reducers: {
    savePaymentInfo: (
      state: PaymentInfoValues,
      value: PayloadAction<PaymentInfoValues>
    ) => {
      return value.payload
    },
    clearPaymentInfo: () => {
      return { ...detail }
    }
  }
})

// Action creators are generated for each case reducer function
export const { savePaymentInfo, clearPaymentInfo } = paymentInfo.actions

export default paymentInfo.reducer
