import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { store } from 'src/stores'
import reportWebVitals from 'src/reportWebVitals'
import { PersistGate } from 'redux-persist/integration/react'
import { BrowserRouter } from 'react-router-dom'
import { persistStore } from 'redux-persist'
import Routers from 'src/routers'
import { ThemeProvider, createTheme } from '@mui/material/styles'

// import styles
import 'src/assets/scss/index.scss'

// custom validation
import 'src/validations'
import BlockUIComponent from 'src/components/BlockUIComponent'
import { LOCAL_STORAGE_KEYS } from './constants/enum'
import { saveNotification } from './stores/NewNotification'
import { NotificationValues } from 'src/models'

const container = document.getElementById('root')!
const root = createRoot(container)
let persistor = persistStore(store)

const theme = createTheme({
  typography: {
    fontFamily: ['Noto Sans JP', 'sans-serif'].join(',')
  }
})

// Define the Payjp interface to avoid TypeScript errors
declare global {
  interface Window {
    Payjp: any
    receiveData: (payload: string) => void // use to handle new notification from app,
    getAppVersion: (payload: string) => void // use to get the version of app
  }
}

window.receiveData = (payload: string) => {
  const data: NotificationValues = JSON.parse(payload)
  store.dispatch(saveNotification(data))
  localStorage.setItem(LOCAL_STORAGE_KEYS.NEW_NOTIFY, JSON.stringify(payload))
}

window.getAppVersion = (version: string) => {
  localStorage.setItem(
    LOCAL_STORAGE_KEYS.APP_VERSION,
    JSON.stringify({
      version
    })
  )
}

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('/firebase-messaging-sw.js')
      .then(registration => {
        console.log('Service Worker registered with scope:', registration.scope)
        return navigator.serviceWorker.ready
      })
      .catch(error =>
        console.error('Service Worker registration failed:', error)
      )
  })
}

root.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Routers />
          <BlockUIComponent />
        </PersistGate>
      </Provider>
    </ThemeProvider>
  </BrowserRouter>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
