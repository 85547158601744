import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams
} from 'react-router-dom'
import Login from 'src/pages/Login'
import RegisterCompleted from 'src/pages/Register/completed'
import Register from 'src/pages/Register'
import RegisterVerify from 'src/pages/Register/verify'
import path from 'src/routers/Path'
import AuthRoute from './AuthRoute'
import UnAuthRoute from './UnAuthRoute'
import ForgotPassword from 'src/pages/ForgotPassword'
import ResetPassword from 'src/pages/ForgotPassword/reset-password'
import ResetPasswordCompleted from 'src/pages/ForgotPassword/completed'
import ChangePassword from 'src/pages/Profile/ChangePassword'
import Profile from 'src/pages/Profile'
import ChangeEmail from 'src/pages/Profile/ChangeEmail'
import ChangeEmailVerify from 'src/pages/Profile/ChangeEmail/verify'
import VehicleCreate from 'src/pages/VehicleInformation'
import ContactForm from 'src/pages/Contact'
import MyPage from 'src/pages/MyPage'
import WithdrawVerify from 'src/pages/Withdrawal/verify'
import TermOfUse from 'src/pages/TermOfUse'
import PrivacyPolicy from 'src/pages/PrivacyPolicy'
import CommercialTransactions from 'src/pages/CommercialTransactions'
import Construction from 'src/pages/Construction'
import FAQ from 'src/pages/FAQ'
import NotificationList from 'src/pages/Notification'
import NotificationDetail from 'src/pages/Notification/detail'
import NotificationIndividualDetail from 'src/pages/Notification/individualDetail'
import InvoiceDetail from 'src/pages/Invoice'
import PaymentInformationUpdate from 'src/pages/PaymentInformation'
import PaymentInformationDetail from 'src/pages/PaymentInformation/detail'
import WithdrawCompleted from 'src/pages/Withdrawal/completed'
import NotFound from 'src/pages/NotFound'
import { isMobileDevice, loadScript, saveDataAfterLogin } from 'src/utils'
import { useCallback, useEffect } from 'react'
import { DeviceTokenValues } from 'src/models'
import { saveDeviceToken } from 'src/stores/DeviceToken'
import { useDispatch } from 'react-redux'
import { firebaseApp } from 'src/firebase'
import { apiSaveDeviceToken } from 'src/services/UserService'
import { store } from 'src/stores'
import textJP from 'src/langs/ja'
import { setInitPayJP } from 'src/stores/InitPayJP'
import PaymentConfirm from 'src/pages/PaymentConfirm'

function Routers() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const tokenParam = searchParams.get('tokenValue') as string
  const token = store.getState().token
  const deviceToken = store.getState().deviceToken

  const getTokenAndSave = useCallback(async () => {
    const registration = await navigator.serviceWorker.ready // Wait for service worker to be ready
    const messaging = firebaseApp.messaging()
    const tokenFCM = await messaging.getToken({
      vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
      serviceWorkerRegistration: registration // Use the service worker registration
    })

    if (!deviceToken) {
      const payload: DeviceTokenValues = {
        token: tokenFCM,
        platform: null
      }
      dispatch(saveDeviceToken(tokenFCM))
      apiSaveDeviceToken(payload)
    }
  }, [dispatch, deviceToken])

  const requestNotificationPermission = useCallback(async () => {
    if (token) {
      if (Notification.permission === 'granted') {
        await getTokenAndSave()
      } else {
        const permission = await Notification.requestPermission()
        if (permission === 'granted') {
          await getTokenAndSave()
        }
      }
    }
  }, [getTokenAndSave, token])

  useEffect(() => {
    const scriptSrc = 'https://js.pay.jp/v2/pay.js'

    const handleLoad = () => {
      const payjp = window.Payjp(process.env.REACT_APP_PAYJP_PUBLIC_KEY, {
        frame: false
      })
      window.Payjp = payjp
      dispatch(setInitPayJP())
    }

    const handleError = () => {
      console.error(textJP.common.error.system_error_mess_1)
    }

    if (!window.Payjp) {
      loadScript(scriptSrc, handleLoad, handleError)
    }
  }, [dispatch])

  useEffect(() => {
    const handleTokenParam = async () => {
      await saveDataAfterLogin(tokenParam)
      navigate(location.pathname)
    }

    if (tokenParam) {
      handleTokenParam()
    }
  }, [tokenParam, location, navigate])

  useEffect(() => {
    if (!isMobileDevice()) {
      requestNotificationPermission()
    }
  }, [requestNotificationPermission])

  return (
    <Routes>
      {/* Require authen */}
      <Route element={<AuthRoute />}>
        <Route path={path.profile} element={<Profile />} />
        <Route path={path.change_password} element={<ChangePassword />} />
        <Route path={path.change_email} element={<ChangeEmail />} />
        <Route
          path={path.change_email_verify}
          element={<ChangeEmailVerify />}
        />
        <Route path={path.vehicle_create} element={<VehicleCreate />} />
        <Route path={path.withdraw_verify} element={<WithdrawVerify />} />
        <Route path={path.notification_list} element={<NotificationList />} />
        <Route
          path={path.notification_detail()}
          element={<NotificationDetail />}
        />
        <Route
          path={path.notification_individual_detail()}
          element={<NotificationIndividualDetail />}
        />
        <Route path={path.invoice_detail()} element={<InvoiceDetail />} />
        <Route
          path={path.payment_infor_update}
          element={<PaymentInformationUpdate />}
        />
        <Route
          path={path.payment_infor_detail}
          element={<PaymentInformationDetail />}
        />
        <Route path={path.payment_confirm} element={<PaymentConfirm />} />
      </Route>

      <Route element={<UnAuthRoute />}>
        <Route path={path.login} element={<Login />} />
        <Route path={path.register} element={<Register />} />
        <Route path={path.register_verify} element={<RegisterVerify />} />
        <Route path={path.register_completed} element={<RegisterCompleted />} />
        <Route path={path.forgot_password} element={<ForgotPassword />} />
        <Route path={path.reset_password} element={<ResetPassword />} />
        <Route
          path={path.reset_password_completed}
          element={<ResetPasswordCompleted />}
        />
        <Route path={path.withdraw_completed} element={<WithdrawCompleted />} />
      </Route>

      <Route path={path.home} element={<MyPage />} />
      <Route path={path.construction} element={<Construction />} />
      <Route path={path.faq} element={<FAQ />} />
      <Route path={path.term_of_use} element={<TermOfUse />} />
      <Route path={path.privacy_policy} element={<PrivacyPolicy />} />
      <Route
        path={path.commercial_transactions}
        element={<CommercialTransactions />}
      />
      <Route path={path.create_contact} element={<ContactForm />} />

      {/* Not found route */}
      <Route path='*' element={<NotFound />} />
    </Routes>
  )
}

export default Routers
